import classes from "./DocumentsContent.module.css";

import MotionCard from "../UI/Card";
import DocumentList from "./DocumentList";

const DocumentsContent = () => {
  return (
    <MotionCard
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className={classes.card}
    >
      <DocumentList />
    </MotionCard>
  );
};

export default DocumentsContent;
