import classes from "./InformationList.module.css";

import InformationFragment from "./InformationFragment";

const InformationList = (props) => {
  const { informations } = props;

  const infoList = informations
    .map((info) => (
      <InformationFragment
        key={info.id}
        id={info.id}
        title={info.title}
        date={info.date}
      />
    ))
    .reverse();

  return <ul className={classes.list}>{infoList}</ul>;
};

export default InformationList;
