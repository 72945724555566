import { routes } from "../routes/routes";
import Obavestenje_za_remont_malog_lifta_27 from "../assets/Obavestenje_za_remont_malog_lifta_27.12.2024.pdf";

// linkovi se u text unose u sledecem formatu: <link to='putanja linka'>rec koja se linkuje</link>
export const informations = [
  {
    id: 1,
    title: "Нове накнаде за одржавање зграде",
    text: "Сходно одлукама Скупштине стамбене заједнице почев од 01. фебруара 2024. године мењају се висине месечних накнада за одржавање зграде, и износе: <br>- Накнада за одржавање зграде:  300,00 динара: <br>-  Накнада за управљање зградом: 350,00 динара; <br>- Накнада за редовно одржавање лифтова: 300,00 динара; <br>- Средства за потребе ремонта малог лифта: 1.500,00 динара.",
    date: "31.01.2024",
  },
  {
    id: 2,
    title: "Реконструкција малог лифта",
    text: `<link to='${Obavestenje_za_remont_malog_lifta_27}'>Реконструкција малог лифта</link> почиње после празника и трајаће две недеље. За то време можете користити велики лифт. Уговор о реконструкцији можете погледати на страници: <link to='/${routes.documents}'>Документа</link>.`,
    date: "27.12.2024",
  },
];
