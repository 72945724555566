import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import classes from "./SingleInformation.module.css";

const SingleInformation = (props) => {
  const { informations } = props;
  const params = useParams();

  const [singleInfoTitleContent, setSingleInfoTitleContent] = useState(null);
  const [singleInfoTextContent, setSingleInfoTextContent] = useState(null);

  useEffect(() => {
    const singleInfo = informations?.find(
      (info) => info.id === Number(params.infoId)
    );

    if (!singleInfo) return;

    setSingleInfoTitleContent(singleInfo.title);

    const parseTextWithLinks = (text) => {
      const regex = /<link to='(.*?)'>(.*?)<\/link>/g;
      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          segments.push(text.substring(lastIndex, match.index));
        }

        if (match[1].endsWith(".pdf")) {
          segments.push(
            <a
              key={match[1]}
              href={match[1]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {match[2]}
            </a>
          );
        } else {
          segments.push(
            <Link key={match[1]} to={match[1]}>
              {match[2]}
            </Link>
          );
        }

        lastIndex = regex.lastIndex;
      }

      if (lastIndex < text.length) {
        segments.push(text.substring(lastIndex));
      }

      return segments;
    };

    const formattedInfoText = singleInfo.text
      .split("<br>")
      .map((line, idx) => <p key={idx}>{parseTextWithLinks(line)}</p>);

    setSingleInfoTextContent(formattedInfoText);
  }, [informations, params.infoId]);

  return (
    <article className={classes.article}>
      <h1>{singleInfoTitleContent}</h1>
      <div>{singleInfoTextContent}</div>
    </article>
  );
};

export default SingleInformation;
